import { For, Match, Show, Switch } from "solid-js";
import { urlRs } from "~/utils/url";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";

import type { TermEntityReferenceField } from "~/types/drupal_jsonapi";

import "./NouveauNeufLogos.css";

import PastilleAccess from "~/img/logos/pastille-access.svg";
import PastilleCogedimAvantages from "~/img/logos/pastille-avantages.svg";
import PastilleCogedimSignature from "~/img/logos/pastille-cogedim-signature.svg";

export default function NouveauNeufLogos(props: {
  zone: string;
  segmentsMkg: TermEntityReferenceField[];
}) {
  const settingsContext = useDrupalSettingsContext();

  return (
    <>
      <div class="logos-nouveau-neuf" data-test="logos-nn">
        <For each={props.segmentsMkg}>
          {(segmentMkg: TermEntityReferenceField) => (
            <span
              class={`logo logo-${segmentMkg.name.toLowerCase().replace(" ", "-")}`}
              data-test={`logo-nn-${segmentMkg.name.toLowerCase().replace(" ", "-")}`}
            >
              <Show when={props.zone === "program"}>
                <Switch>
                  <Match when={segmentMkg.name === "Access"}>
                    <a
                      href={urlRs(
                        "landings",
                        "/cogedim-pour-vous/pourquoi-choisir-cogedim/le-nouveau-neuf/cogedim-access/",
                      )}
                      title="Access"
                    >
                      <Show
                        when={settingsContext.switch_logos}
                        fallback={<PastilleAccess />}
                      >
                        <span
                          class="pastille-svg"
                          innerHTML={
                            settingsContext.marketing_segments_terms.access
                              .field_logo_main_svg
                          }
                        />
                      </Show>
                    </a>
                  </Match>
                  <Match when={segmentMkg.name === "Avantages"}>
                    <a
                      href={urlRs(
                        "landings",
                        "/cogedim-pour-vous/pourquoi-choisir-cogedim/le-nouveau-neuf/cogedim-avantages/",
                      )}
                      title="Cogedim Avantages"
                    >
                      <Show
                        when={settingsContext.switch_logos}
                        fallback={<PastilleCogedimAvantages />}
                      >
                        <span
                          class="pastille-svg"
                          innerHTML={
                            settingsContext.marketing_segments_terms.invest
                              .field_logo_main_svg
                          }
                        />
                      </Show>
                    </a>
                  </Match>
                  <Match when={segmentMkg.name === "Cogedim Signature"}>
                    <a
                      href={urlRs(
                        "landings",
                        "/cogedim-pour-vous/pourquoi-choisir-cogedim/le-nouveau-neuf/cogedim-signature/",
                      )}
                      title="Signature"
                    >
                      <Show
                        when={settingsContext.switch_logos}
                        fallback={<PastilleCogedimSignature />}
                      >
                        <span
                          class="pastille-svg"
                          innerHTML={
                            settingsContext.marketing_segments_terms.signature
                              .field_logo_main_svg
                          }
                        />
                      </Show>
                    </a>
                  </Match>
                </Switch>
              </Show>
              <Show when={props.zone === "card"}>
                <Switch>
                  <Match when={segmentMkg.name === "Access"}>
                    <Show
                      when={settingsContext.switch_logos}
                      fallback={<PastilleAccess />}
                    >
                      <span
                        class="pastille-svg"
                        innerHTML={
                          settingsContext.marketing_segments_terms.access
                            .field_logo_main_svg
                        }
                      />
                    </Show>
                  </Match>
                  <Match when={segmentMkg.name === "Avantages"}>
                    <Show
                      when={settingsContext.switch_logos}
                      fallback={<PastilleCogedimAvantages />}
                    >
                      <span
                        class="pastille-svg"
                        innerHTML={
                          settingsContext.marketing_segments_terms.invest
                            .field_logo_main_svg
                        }
                      />
                    </Show>
                  </Match>
                  <Match when={segmentMkg.name === "Cogedim Signature"}>
                    <Show
                      when={settingsContext.switch_logos}
                      fallback={<PastilleCogedimSignature />}
                    >
                      <span
                        class="pastille-svg"
                        innerHTML={
                          settingsContext.marketing_segments_terms.signature
                            .field_logo_main_svg
                        }
                      />
                    </Show>
                  </Match>
                </Switch>
              </Show>
            </span>
          )}
        </For>
      </div>
    </>
  );
}
